import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { getSurveyData, updateScores, saveResponseToSalesForce, saveResponseToBackend, getSurveyApiAccessToken } from '../redux/slices/surveySlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

import './survey.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { themeJson } from "./theme";

import { storeQuestionAndOption } from '../redux/slices/surveySlice';

const customCss = {
    "question": {
        "content": "question-content",
        "answered": "question-answered",
        "titleRequired": "question-title-required"
    }
};

const SurveyViewer = () => {
    const navigate = useNavigate();
    const surveyStore = useSelector(getSurveyData)
    const taggedSurveyData = useSelector((state) => state.survey.taggedSurveyData);
    const dispatch = useDispatch()
    const survey = useMemo(() => {
        const surveyInstance = new Model(surveyStore);
        surveyInstance.applyTheme(themeJson);
        surveyInstance.css = customCss;
        return surveyInstance;
      }, [surveyStore])
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
    const [firstName, setFirstName] = useState("")



    const surveyComplete = useCallback(async (sender) => {
        const surveyResponse = sender.data;
        dispatch(updateScores(surveyResponse))
        const questionOptionArray = [];

        for (const key in sender['valuesHash']) {
          if (sender['valuesHash'].hasOwnProperty(key) && !["FirstName", "LastName", "Email"].includes(key)) {
            questionOptionArray.push({
              questionText: key.toString(),
              selectedOptionText: sender['valuesHash'][key].toString()
            });
          }
        }
         
        dispatch(storeQuestionAndOption(questionOptionArray));
        const surveyApiToken = await dispatch(getSurveyApiAccessToken());
        dispatch(saveResponseToBackend(surveyApiToken.payload));
        dispatch(saveResponseToSalesForce());
        setFirstName(surveyResponse["FirstName"]);
        setIsSurveyCompleted(true);
    }, [dispatch]);

    useEffect(() => {
        const onCompleteCallback = () => {
          surveyComplete(survey);
        };
    
        survey.onComplete.add(onCompleteCallback);
        return () => {
          survey.onComplete.remove(onCompleteCallback);
        };
      }, [surveyComplete, survey]);;


  return (
        <div className="survey">
          <img src='https://uploads-ssl.webflow.com/62bee462ea6eea41695d52ae/64968516f2887dcc06de86af_TENx10_Main%20Logo%20Black.svg' alt='' className='logo'/>
          { surveyStore.isLoading ? (
            
            <Loader /> // Render the loader when isLoading is true
        ) : (
            <>
              <Survey model={survey} id="surveyContainer" />
              {isSurveyCompleted && (
                  navigate('/report', { state: { firstName: firstName, taggedSurveyData: taggedSurveyData } })
                )
            }</>
        )}
        </div>
      );
    }

export default SurveyViewer;