import React, {  useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import logoImg  from '../../assets/logo/TENx10_Main.png'
import categoriesData from './reportData'
import ReactHtmlParser from 'html-react-parser';
import PolarChart from './polarChart';
import './index.css';

const unselectedContentMenuStyle = {
    cursor: "pointer"
};

const selectedMenuStyle = {
    cursor: "pointer",
    fontWeight: 'bold',
};

function SurveyReport() {
    const location = useLocation();
    const assesementRef = useRef();
    const resourcesRef = useRef();  
    const [menuIndex, setMenuIndex] = useState(0)
    const [categoryIndex,setCategoryIndex] = useState(0)
    const { state } = location;

    const menuItems = [
        {
            menuText: 'Your Assessment',
            ref: assesementRef
        },
        {
            menuText: 'Resources',
            ref: resourcesRef
        }
    ];

    const firstName = state && state.firstName
    const taggedSurveyData = state && state.taggedSurveyData

    const handleScroll = (scrollRef, index) => {
        if (menuIndex !== index) {
            setMenuIndex(index);
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    function assignScores(){
        let scores = []
        Object.keys(taggedSurveyData).forEach((key) => {
            scores.push(taggedSurveyData[key].percentileScore)
        })
       return scores;
    }

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${month}-${day}-${year}`;
    };

    return (
        <div className="flex flex-col">
            <div className="sticky-menu">
                <div className="header">
                    <img src={logoImg} alt="logo" height='70' width='160' className="logo-img" />
                    <ul className='nav-header'>
                        <li>About</li>
                        <li>Resources</li>
                    </ul>
                </div>
                <div className='report-header'>
                    <h1 className="report-header-title">Relational Discipleship Inventory</h1>
                    <div className='content-menu-wrapper'>
                        {menuItems.map((menu, index) => (
                            <React.Fragment key={index}>
                                <div className="menu-container">
                                    <a onClick={() => handleScroll(menu.ref, index)} style={menuIndex === index ? selectedMenuStyle : unselectedContentMenuStyle}>
                                        {menu.menuText}
                                    </a>
                                    <div style={menuIndex === index ? { display: "flex" } : { display: "none" }} className="underline"></div>
                                </div>
                                <div className='separator' style={index === (menuItems.length-1) ? {display: 'none'} : {display: 'flex'}}></div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>

             <div className='assessment-content-wrapper' id="assesment-page" ref={assesementRef}>
               <div className='summary-assessment-content-header'>
                    <h2>Welcome  {firstName}!</h2>
                    <p>ASSESSMENT COMPLETION DATE: {getCurrentDate()}</p>
                </div>
                <div className="assesment-content-title">
                    <h2>Your Assessment</h2>
                    <p>TO FIND OUT MORE ABOUT YOUR MINISTRY SCORE AND HOW TO IMPROVE, PLEASE CLICK ON EACH OF THESE 6 CATEGORIES.</p>
                </div>
                
                <div className="assesment-categories-wrapper">
                    {categoriesData.map((category, index) => (
                        <div key={index} className="category-menu-container">
                            <div style={{ width: "fit-content" }}>
                                <a
                                    className='assesment-category-text'
                                    onClick={() => setCategoryIndex(index)}
                                    style={categoryIndex === index ? selectedMenuStyle : unselectedContentMenuStyle}>
                                    {category.title}
                                </a>
                                <div style={categoryIndex === index ? { display: "flex" } : { display: "none" }} className="category-menu-underline"></div>
                            </div>
                        </div>
                        
                    ))}
                </div>
                <div className="chart-wrapper">
                    <PolarChart categories={categoriesData} selectedCategoryIndex={categoryIndex} series={assignScores()} setCategoryIndex={setCategoryIndex} />
                </div>
                <div className="assesment-points-container">
                    <div className="assesment-points-title">
                        <h3 className="assesment-points-title-text">{categoriesData[categoryIndex].title}: {taggedSurveyData[categoriesData[categoryIndex].title].scoreLevel}</h3>
                    </div>
                    <div>
                        <p>{categoriesData[categoryIndex].summary}</p>
                    </div>
                </div>
            </div>

            <div className='resources-content-wrapper' ref={resourcesRef}>
                <div>
                    <h3 className="resources-content-title">{categoriesData[categoryIndex].title}</h3>
                    <img src={categoriesData[categoryIndex].imageSrc} alt="community engagement" className="community-engagement-img"/>
                </div>
                <div className="resources-content">
                    <p className='categoryDescription'>
                        {ReactHtmlParser(categoriesData[categoryIndex].longerDescription)}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SurveyReport;
