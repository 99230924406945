// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {
  font-family: 'GalGothicMedium', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background-color: #FCFCFA;
}

#root {
  min-width: 400px;
  width: 70%;
  margin: 0 auto;
  color: #14120E;
  @media (max-width: 785px) {
    width: 90%;
  }
}

code {
  font-family: source-code-pro, neue-haas-grotesk-display,sans-serif;
}

h1 {
  font-family: 'GalGothicExtraBold', sans-serif;
  font-size: 56px;
  line-height: 90%;
  padding: 10px 0;
}

h2 {
  font-family: 'GalGothicExtraBold', sans-serif;
  font-size: 40px;
  line-height: 110%;
}

h3 {
  font-family: 'GalGothicExtraBold', sans-serif;
  font-size: 32px;
  line-height: 120%;
}

h4 {
  font-family: 'GalGothicExtraBold', sans-serif;
  font-size: 28px;
  line-height: 120%;

}

h5 {
  font-family: 'GalGothicExtraBold', sans-serif;
  font-size: 22px;
  line-height: 130%;
}

h6 {
  font-family: 'GalGothicExtraBold', sans-serif;
  font-size: 18px;
  line-height: 140%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,0CAA0C;EAC1C,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,cAAc;EACd,cAAc;EACd;IACE,UAAU;EACZ;AACF;;AAEA;EACE,kEAAkE;AACpE;;AAEA;EACE,6CAA6C;EAC7C,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,6CAA6C;EAC7C,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,6CAA6C;EAC7C,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,6CAA6C;EAC7C,eAAe;EACf,iBAAiB;;AAEnB;;AAEA;EACE,6CAA6C;EAC7C,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,6CAA6C;EAC7C,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["\n* {\n  font-family: 'GalGothicMedium', sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  background-color: #FCFCFA;\n}\n\n#root {\n  min-width: 400px;\n  width: 70%;\n  margin: 0 auto;\n  color: #14120E;\n  @media (max-width: 785px) {\n    width: 90%;\n  }\n}\n\ncode {\n  font-family: source-code-pro, neue-haas-grotesk-display,sans-serif;\n}\n\nh1 {\n  font-family: 'GalGothicExtraBold', sans-serif;\n  font-size: 56px;\n  line-height: 90%;\n  padding: 10px 0;\n}\n\nh2 {\n  font-family: 'GalGothicExtraBold', sans-serif;\n  font-size: 40px;\n  line-height: 110%;\n}\n\nh3 {\n  font-family: 'GalGothicExtraBold', sans-serif;\n  font-size: 32px;\n  line-height: 120%;\n}\n\nh4 {\n  font-family: 'GalGothicExtraBold', sans-serif;\n  font-size: 28px;\n  line-height: 120%;\n\n}\n\nh5 {\n  font-family: 'GalGothicExtraBold', sans-serif;\n  font-size: 22px;\n  line-height: 130%;\n}\n\nh6 {\n  font-family: 'GalGothicExtraBold', sans-serif;\n  font-size: 18px;\n  line-height: 140%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
