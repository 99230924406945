import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";

function ApexChart(props) {
  const selectedCategoryIndex = props.selectedCategoryIndex;

  const selectedColors = useMemo(() => {
    return ['#FF5C06', '#3241FF', '#DB7190', '#00744B', '#E99C56', '#151756']
  }, []);

  const chartColors = useMemo(() => {
    return ['#FED7C1', '#CDCFFF', '#F5DCE2', '#BEDCD2', '#FFD5c0', '#C4C5D4']
  }, []);
  
  
  const [options, setOptions] = useState({
    chart: {
      width: 500,
      type: 'polarArea',
      events: {
        dataPointSelection: (event, chartContext, config) => { 
          props.setCategoryIndex(config.dataPointIndex)
        }
      }
    },
    labels: props.categories.map(category => category.title),
    fill: {
      opacity: 1,
      colors: chartColors, // Specify the colors here
    },
    stroke: {
      colors: ['#fff'],
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    dataLabels: {
      enabled: false, // Set to false to hide data point labels (pointers)
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'light',
        shadeIntensity: 0.6,
      },
    },
    tooltip: {
      enabled: true,
      custom: function({ seriesIndex, dataPointIndex, w }) {
        return `<div style="padding: 5px">${ props.categories[seriesIndex].title}</div>`;
      },
    }
  })

  useEffect(() => {
    if (selectedCategoryIndex !== null) {
      const updatedColors = [...chartColors];
      updatedColors[selectedCategoryIndex] = selectedColors[selectedCategoryIndex];
      setOptions({
        ...options,
        fill: {
          ...options.fill,
          colors: updatedColors,
        },
      });
    }
  }, [selectedCategoryIndex, chartColors, options, selectedColors]);



  return (
    <div id="chart" className="chart">
      <Chart options={options} series={props.series} type="polarArea" />
    </div>
  );
}

export default ApexChart;
