import communityEngagement from '../../assets/images/Community_Engagement.jpg';
import familyEngagement from '../../assets/images/Family_Engagement.jpg';
import personalGrowth from '../../assets/images/Personal_Growth.jpg';
import buildingRelationships from '../../assets/images/Relationship.jpg';
import focusingOnJesus from '../../assets/images/Teaching_Focus.jpg';
import servingOthers from '../../assets/images/Volunteer_Engagement.jpg'

const categoriesData = [
    {
        title: "Building Relationships",
        summary: "Building relationships between adults and young people strengthens your youth ministry environment.",
        longerDescription: `
            <b>Relationships</b> develop when volunteers are invested as <b>mentors</b> in your ministry. This
            helps create an environment where your young people have a sense of <b>belonging</b> in
            the faith community, and feel supported as they integrate their faith with their
            understanding of their <b>identity</b>.<br><br>

            A <b>high score</b> in this category indicates that there are strong relationships
            between the adults and young people in your ministry. You prioritize building
            relationships in your youth ministry, and beyond.<br><br>

            A <b>low score</b> in this category indicates that relationships between adults and
            young people are limited, and are likely not a high priority for you and your
            volunteers. Strengthening this area will likely help your young people develop
            more robust faith.<br><br>

            For more resources on <b>training mentors</b>, helping create a sense of <b>belonging</b>, or
            supporting young people in their faith <b>identity</b>, visit TENx10.org.`,
        imageSrc: buildingRelationships
    },
    {
        title: "Connecting with Families",
        summary: "Connecting with families is an important way of creating a youth ministry environment where young people feel they belong.",
        longerDescription: `
            Each young person is connected in some way with <b>family</b> or caregivers who have
            shaped, and continue to shape, who they are. Creating meaningful connections between
            church and home helps foster a stronger sense of <b>belonging</b> for young people, and
            better integrates their faith <b>identity</b> with their home identity.<br><br>

            A <b>high score</b> in this category indicates that you value integrating your young people's
            family into your ministry through regular communication and support.<br><br>

            A <b>low score</b> in this category indicates that supporting families and caregivers is not
            currently a strength in your ministry. Deepening connection with your young people's
            families and caregivers will help your young people better integrate their growing faith
            with their lives at home.<br><br>

            For more resources on building meaningful connections with <b>families</b> that strengthen
            your young people's sense of <b>belonging</b> and faith <b>identity</b>, visit TENx10.org.`,
        imageSrc: familyEngagement
    },
    {
        title: "Empowering Mentors",
        summary: "Youth ministries thrive when adults are empowered to serve as mentors for young people.",
        longerDescription: `
            Youth ministries thrive when adults are meaningfully engaged as <b>mentors</b> for young
            people. In these relationships, your volunteers can support your young people as they
            explore their faith <b>identity</b> and their daily lives.<br><br>

            A <b>high score</b> in this category indicates that the volunteers in your youth ministry are
            meaningfully engaged as mentors with young people.<br><br>

            A <b>low score</b> in this category indicates that the volunteers in your youth ministry could
            more meaningfully support your young people in their faith.<br><br>

            For more resources on training and equipping volunteers and <b>mentors</b> to help create
            an environment where young people's faith <b>identity</b> grows, visit TENx10.org.`,
            imageSrc: communityEngagement
    },
    {
        title: "Growing Personally",
        summary: "Youth leaders experience personal growth when they engage in practices, both personal and communal, that help them grow as followers of Jesus.",
        longerDescription: `
            Youth ministries thrive when adults are meaningfully engaged as <b>mentors</b> for young
            people. In these relationships, your volunteers can support your young people as they
            explore their faith <b>identity</b> and their daily lives.<br><br>

            A <b>high score</b> in this category indicates that the volunteers in your youth ministry are
            meaningfully engaged as mentors with young people.<br><br>

            A <b>low score</b> in this category indicates that the volunteers in your youth ministry could
            more meaningfully support your young people in their faith.<br><br>

            For more resources on training and equipping volunteers and <b>mentors</b> to help create
            an environment where young people's faith <b>identity</b> grows, visit TENx10.org.`,
        imageSrc: personalGrowth
    },
    {
        title: "Serving Others",
        summary: "Engaging young people in the faith community beyond youth ministry by serving together helps foster a sense of belonging.",
        longerDescription: `
            Engaging young people in the faith community beyond youth ministry by <b>serving
            together</b> helps foster a sense of <b>belonging</b> that continues beyond graduation.
            Providing opportunities for young people to join alongside others from their church in the
            broader community helps them develop an understanding of their God-given <b>purpose</b> in
            the world.<br><br>

            A <b>high score</b> in this category indicates that you work to involve your young people in
            service and justice opportunities within your church and in the broader community.<br><br>

            A <b>low score</b> in this category indicates that engaging in your church or broader
            community is not a current emphasis in your ministry. Strengthening the ways your
            young people are meaningfully engaged in the broader church and community will help
            them foster a stronger sense of belonging and purpose.<br><br>

            For more information about helping your young people develop a sense that they
            <b>belong</b> and are valuable in the faith community, and have a sense of <b>purpose</b> shaped
            by their faith, visit TENx10.org.`,
        imageSrc: servingOthers
    },
    {
        title: "Teaching Focused on Jesus",
        summary: "Teaching about Jesus in our conversations with young people helps them understand how faith connects to their lives.",
        longerDescription: `
            <b>Teaching</b> happens every time we, or one of the other adult leaders in our ministry,
            have a conversation with a young person about faith and life. The strongest teaching
            <b>about Jesus</b>, often points young people to the truth of scripture, helps young people
            see how they <b>belong</b> in the faith community, and how following Jesus shapes their
            <b>identities</b> and gives their lives <b>purpose</b>.<br><br>

            A <b>high score</b> in this category indicates that your teaching, whether formally or more
            informally, often incorporates scripture, and is centered on Jesus.<br><br>

            A <b>low score</b> in this category indicates that your teaching could be more strongly rooted
            in the truth of scripture and the life of Jesus. Strengthening this area will likely help your
            young people better understand how their faith can shape their daily life.<br><br>

            For more resources on creating a ministry environment where young people feel they
            <b>belong</b> in the family of Christ, develop <b>identities</b> focused on Jesus and see how their
            faith gives their lives <b>purpose</b>, visit TENx10.org.`,
        imageSrc: focusingOnJesus
    }
]

export default categoriesData;