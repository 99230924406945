import React, { useRef, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import logoImg  from '../../assets/logo/TENx10_Main.png'
import categoriesData from '../reports/reportData'
import PolarChart from '../reports/polarChart';
import './index.css';
import { getSurveyApiAccessToken } from '../../redux/slices/surveySlice';
import { useDispatch } from 'react-redux';

function AllSurveyReports() {
    const location = useLocation();
    const assesementRef = useRef();
    const [categoryIndex,setCategoryIndex] = useState(0);
    const [data, setData] = useState([]);
    const [scoresList, setScoresList] = useState([]);
    const { state } = location;
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                const surveyApiToken = await dispatch(getSurveyApiAccessToken());
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/responses/`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${surveyApiToken.payload}`,
                        "Accept": "*/*",
                    }
                });
                const data = await response.json(); // Parse the response JSON
                setData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const scoresList = data.map(item => assignScores(item.tagged_survey_data));
            setScoresList(scoresList);
        }
    }, [data]);

    function assignScores(taggedSurveyData) {
        let scores = [];
        Object.keys(taggedSurveyData).forEach((key) => {
            scores.push(taggedSurveyData[key].percentileScore);
        });
        return scores;
    }

    console.log(data);
    console.log(scoresList);

    return (
        <div className="flex flex-col">
            <div className="sticky-menu">
                <div className="header">
                    <img src={logoImg} alt="logo" height='70' width='160' className="logo-img" />
                    <ul className='nav-header'>
                        <li>About</li>
                        <li>Resources</li>
                    </ul>
                </div>
            </div>

            <div className='assessment-content-wrapper' id="assesment-page" ref={assesementRef}>
                <div className='report-header'>
                    <h1 className="report-header-title">Relational Discipleship Inventory</h1>
                </div>
                <div className="assesment-content-title">
                    <h2>All assessments</h2>
                </div>

                {data.map((item, index) => (
                    <div key={index} className="chart-wrapper">
                        <div className="chart-respondent-name">
                            <h3>{item.respondent.first_name} {item.respondent.last_name}</h3>
                        </div>
                        <div className="chart-polar">
                            {scoresList[index] && // Verifica se scoresList[index] não é undefined
                                <PolarChart categories={categoriesData} selectedCategoryIndex={categoryIndex} series={scoresList[index]} setCategoryIndex={setCategoryIndex} />
                            }
                        </div>
                        <div className="chart-scores">
                            <ul>
                                {Object.keys(item.tagged_survey_data).map((key, idx) => (
                                    <li key={idx}>
                                        {key}: <span>Actual Score - <span className="category-score">{item.tagged_survey_data[key].actualScore}</span>, Score Level - <span className="category-score">{item.tagged_survey_data[key].scoreLevel}</span></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AllSurveyReports;
