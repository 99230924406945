import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "survey-core/defaultV2.min.css";
import Survey from "./components/survey";
import {
  fetchSurveyData,
  setTaggedData,
  getSalesForceAccessToken,
  setAccessToken,
} from "./redux/slices/surveySlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import SurveyReport from "./components/reports/index";
import AllSurveyReports from "./components/allReports/index";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesForceAccessToken()).then((state) => {
      dispatch(setAccessToken(state.payload.access_token));
    });
    dispatch(fetchSurveyData()).then((state) => {
      dispatch(setTaggedData(state.payload));
    });
  }, [dispatch]);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Survey />} />
        <Route path="/report" element={<SurveyReport />} />
        <Route path="/all-reports" element={<AllSurveyReports />} />
      </Routes>
    </Router>
  );
}

export default App;
