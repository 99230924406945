// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./gal-gothic/web/GalGothic-Extrabold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./gal-gothic/web/GalGothic-Extrabold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./gal-gothic/otf/GalGothic-Extrabold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./gal-gothic/ttf/GalGothic-Extrabold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./gal-gothic/web/GalGothic-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./gal-gothic/web/GalGothic-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./gal-gothic/otf/GalGothic-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./gal-gothic/ttf/GalGothic-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./pitch/Pitch-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'GalGothicExtrabold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
       url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype'),
       url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}

@font-face {
  font-family: 'GalGothicMedium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
       url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff'),
       url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('opentype'),
       url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('truetype');
}

@font-face {
  font-family: 'Pitch';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('opentype');
}
`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC;;;iEAGuE;AACzE;;AAEA;EACE,8BAA8B;EAC9B;;;iEAGoE;AACtE;;AAEA;EACE,oBAAoB;EACpB,+DAAwD;AAC1D","sourcesContent":["@font-face {\n  font-family: 'GalGothicExtrabold';\n  src: url('./gal-gothic/web/GalGothic-Extrabold.woff2') format('woff2'),\n       url('./gal-gothic/web/GalGothic-Extrabold.woff') format('woff'),\n       url('./gal-gothic/otf/GalGothic-Extrabold.otf') format('opentype'),\n       url('./gal-gothic/ttf/GalGothic-Extrabold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'GalGothicMedium';\n  src: url('./gal-gothic/web/GalGothic-Medium.woff2') format('woff2'),\n       url('./gal-gothic/web/GalGothic-Medium.woff') format('woff'),\n       url('./gal-gothic/otf/GalGothic-Medium.otf') format('opentype'),\n       url('./gal-gothic/ttf/GalGothic-Medium.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Pitch';\n  src: url('./pitch/Pitch-Regular.otf') format('opentype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
