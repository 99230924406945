import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const mappedSurveyTagsToRDI = {
  "Growing Personally": "RDI_Personal_Growth_Score__c",
  "Building Relationships": "Relationships__c",
  "Empowering Mentors": "RDI_Volunteer_Engagement_Score__c",
  "Teaching Focused on Jesus": "RDI_Teaching_Focus_Score__c",
  "Connecting with Families": "RDI_Family_Engagement_Score__c",
  "Serving Others": "RDI_Community_Engagement_Score__c",
};

const scoreRanges = {
  "Growing Personally": {
    Low: [0, 40],
    Medium: [41, 79],
    High: [80, 100],
  },
  "Building Relationships": {
    Low: [0, 40],
    Medium: [41, 75],
    High: [76, 100],
  },
  "Empowering Mentors": {
    Low: [0, 40],
    Medium: [41, 75],
    High: [76, 100],
  },
  "Connecting with Families": {
    Low: [0, 40],
    Medium: [41, 75],
    High: [76, 100],
  },
  "Teaching Focused on Jesus": {
    Low: [0, 45],
    Medium: [46, 75],
    High: [76, 100],
  },
  "Serving Others": {
    Low: [0, 45],
    Medium: [45, 76],
    High: [76, 100],
  },
};

const getScoreLevel = (category, score) => {
  const ranges = scoreRanges[category];

  if (!ranges) {
    return "Invalid category";
  }

  let previousLevel = null;

  for (const level in ranges) {
    const [min, max] = ranges[level];
    if (score >= min && score <= max) {
      return level;
    }
    if (previousLevel && score > ranges[previousLevel][1] && score < min) {
      return level;
    }
    previousLevel = level;
  }

  return "Invalid score";
};

const fetchSurveyData = createAsyncThunk("survey/fetchSurveyData", async () => {
  const response = await axios.get(process.env.REACT_APP_SURVEY_JS_BASE_URL);
  return response.data;
});

const getSalesForceAccessToken = createAsyncThunk("accessToken", async () => {
  const url = `${process.env.REACT_APP_BASE_SALESFORCE_URL}/services/oauth2/token?username=${process.env.REACT_APP_SALESFORCE_APP_USERNAME}&password=${process.env.REACT_APP_SALESFORCE_APP_PASSWORD}&grant_type=password&client_id=${process.env.REACT_APP_SALESFORCE_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_SALESFORCE_APP_CLIENT_SECRET}`;

  const response = await axios.post(url);
  return response.data;
});

const getSurveyApiAccessToken = createAsyncThunk("surveyApiAccessToken", async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/login`;

  const response = await axios.post(url, {}, {
    headers: {
        'X-API-Key': `${process.env.REACT_APP_BACKEND_API_KEY}`,
    },
  });

  return response.data.access_token;
});

const surveySlice = createSlice({
  name: "survey",
  initialState: {
    data: {
      title: "Youth Leader Relational Discipleship Inventory",
      description:
        "Instructions: This assessment is designed to help you, as a youth leader, become aware of your strengths and areas of growth within your ministry. Use it as a tool for self-reflection and as a guide for developing your relational discipleship skills. Please answer each question based on your experiences and practices. There are no right or wrong answers, and your responses will remain confidential. Reflect on your realm of influence, specifically your interactions with the youth you serve.",
      pages: [],
      showProgressBar: "top",
      progressBarType: "questions",
      isLoading: true,
    },
    taggedSurveyData: {
      "Building Relationships": {
        maxPossibleScore: 0,
        actualScore: 0,
        percentileScore: 0,
        questions: [],
        scoreLevel: "",
      },
      "Teaching Focused on Jesus": {
        maxPossibleScore: 0,
        actualScore: 0,
        percentileScore: 0,
        questions: [],
        scoreLevel: "",
      },
      "Connecting with Families": {
        maxPossibleScore: 0,
        actualScore: 0,
        percentileScore: 0,
        questions: [],
        scoreLevel: "",
      },
      "Serving Others": {
        maxPossibleScore: 0,
        actualScore: 0,
        percentileScore: 0,
        questions: [],
        scoreLevel: "",
      },
      "Empowering Mentors": {
        maxPossibleScore: 0,
        actualScore: 0,
        percentileScore: 0,
        questions: [],
        scoreLevel: "",
      },
      "Growing Personally": {
        maxPossibleScore: 0,
        actualScore: 0,
        percentileScore: 0,
        questions: [],
        scoreLevel: "",
      },
    },
    reportBody: {
      FirstName: "",
      LastName: "",
      Email: "",
      Relationships__c: 0,
      RDI_Volunteer_Engagement_Score__c: 0,
      RDI_Teaching_Focus_Score__c: 0,
      RDI_Community_Engagement_Score__c: 0,
      RDI_Family_Engagement_Score__c: 0,
      RDI_Personal_Growth_Score__c: 0,
    },
    questionsOption: [],
    accessToken: "",
  },
  reducers: {
    setTaggedData: (state, action) => {
      action.payload.pages.forEach((page) => {
        page.elements.forEach((element) => {
          if (element.type === "panel") {
            element.elements.forEach((ele) => {
              if (ele.type === "radiogroup") {
                state.taggedSurveyData[element.name].maxPossibleScore +=
                  parseInt(ele.choices[0].value);
                state.taggedSurveyData[element.name].questions = [
                  ...state.taggedSurveyData[element.name].questions,
                  ele.name,
                ];
              }

              if (ele.type === "rating") {
                state.taggedSurveyData[element.name].maxPossibleScore += 5;
                state.taggedSurveyData[element.name].questions = [
                  ...state.taggedSurveyData[element.name].questions,
                  ele.name,
                ];
              }
            });
          }
        });
      });
    },
    updateScores: (state, action) => {
      Object.keys(state.taggedSurveyData).forEach((key) => {
        const questions = state.taggedSurveyData[key].questions;
        questions.forEach((q) => {
          state.taggedSurveyData[key].actualScore += parseInt(
            action.payload[q]
          );
        });

        let percentScore =
          (state.taggedSurveyData[key].actualScore * 100) /
          state.taggedSurveyData[key].maxPossibleScore;
        state.taggedSurveyData[key].percentileScore = percentScore;
        state.taggedSurveyData[key].scoreLevel = getScoreLevel(
          key,
          percentScore
        );
        state.reportBody[mappedSurveyTagsToRDI[key]] = percentScore;
      });

      state.reportBody["FirstName"] = action.payload["FirstName"];
      state.reportBody["LastName"] = action.payload["LastName"];
      state.reportBody["Email"] = action.payload["Email"];
    },
    storeQuestionAndOption: (state, action) => {
      state.questionsOption = action.payload;
    },
    saveResponseToBackend: (state, { payload: surveyApiToken }) => {
      const data = JSON.parse(JSON.stringify(state));

      const responses = [];

      state.questionsOption.forEach(obj => {
        responses.push({
          question_name: obj.questionText,
          response: obj.selectedOptionText
        });
      });

      const response = {
        "respondent": {
          "first_name": data.reportBody.FirstName,
          "last_name": data.reportBody.LastName,
          "email": data.reportBody.Email
        },
        "survey_name": data.data.title,
        "tagged_survey_data": data.taggedSurveyData,
        "responses": responses
      }

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/responses/`,
          response,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${surveyApiToken}`,
              Accept: "*/*",
            },
          }
        )
        .catch((error) => {
          console.log(error);
        });
    },
    saveResponseToSalesForce: (state) => {
      const data = JSON.parse(JSON.stringify(state.reportBody));
      console.log(process.env.REACT_APP_SALESFORCE_ACCESS_TOKEN);
      console.log(process.env.REACT_APP_BASE_SALESFORCE_URL);
      axios
        .post(
          `${process.env.REACT_APP_BASE_SALESFORCE_URL}/services/data/v58.0/sobjects/Contact/`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${state.accessToken}`,
              Accept: "*/*",
            },
          }
        )
        .catch((error) => {
          console.log(error);
        });
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyData.pending, (state) => {})
      .addCase(fetchSurveyData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.data.isLoading = false;
      })
      .addCase(fetchSurveyData.rejected, (state) => {});
  },
});

// Export the async action and reducer
export { fetchSurveyData, getSalesForceAccessToken, getSurveyApiAccessToken };
export const setTaggedData = surveySlice.actions.setTaggedData;
export const setAccessToken = surveySlice.actions.setAccessToken;
export const updateScores = surveySlice.actions.updateScores;
export const storeQuestionAndOption = surveySlice.actions.storeQuestionAndOption;
export const saveResponseToBackend = 
  surveySlice.actions.saveResponseToBackend;
export const saveResponseToSalesForce =
  surveySlice.actions.saveResponseToSalesForce;
export const getSurveyData = (state) => state.survey.data;
export default surveySlice.reducer;
